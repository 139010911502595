import {
  Button,
  Divider,
  Flex,
  Image,
  NumberInput,
  TextInput,
  Select,
  Title,
  Group,
  Box,
  Loader,
  Autocomplete,
  Text,
} from "@mantine/core";
import "./customStyles.css";
import { DatePickerInput } from "@mantine/dates";
import BoatImagePlaceholder from "../../../../../assets/images/icons/boat-image-placeholder.svg";
import { useState, useEffect } from "react";
import { FileToDataUrl } from "shared/utils/file";
import {
  CenterTabContextProvider,
  useCenterTabContext,
} from "./CenterTab.context";
import {
  CountryList,
  Currencies,
  Timezones_List,
  Hours,
  Vat,
  CLUB_TYPE,
  FUEL_TYPE,
} from "shared/Constants/general.const";
import { useClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";
import { useCenterContext } from "../../Center.context";
import { useForm, isEmail, isNotEmpty } from "@mantine/form";
import toast from "react-hot-toast";
import moment from "moment";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { PhoneInput } from "react-international-phone";
import ImageUploader from "Components/Atoms/ImageUploader";

const libraries = ["places"];

const mapContainerStyle = {
  width: "260px",
  height: "180px",
  borderRadius: "16px",
};

const center = {
  lat: 19.076, // Default location
  lng: 72.8777,
};

const MapSearch = ({
  setSelected,
  readOnly,
  cancelFlag,
  setCancelFlag,
  form,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 37.7749, lng: () => -122.4194 }, // Default to San Francisco
      radius: 200 * 1000, // Radius in meters (200km)
    },
  });

  const handleSelect = async (address) => {
    setValue(address, false); // Set the value of the input field without triggering suggestions
    clearSuggestions(); // Clear the suggestions

    try {
      const results = await getGeocode({ address }); // Get the geocode data
      const { lat, lng } = await getLatLng(results[0]); // Extract latitude and longitude
      setSelected({ lat, lng }); // Set selected coordinates in the parent component
    } catch (error) {}
  };

  useEffect(() => {
    if (cancelFlag) {
      handleSelect(form.values.address);
      setCancelFlag(false);
    }
  }, [cancelFlag]);

  useEffect(() => {
    if (form.values.address) {
      handleSelect(form.values.address);
    }
  }, [form.values.address]);

  return (
    <Autocomplete
      label="Address"
      value={value}
      withAsterisk
      onChange={setValue}
      readOnly={readOnly}
      data={status === "OK" ? data.map(({ description }) => description) : []}
      onOptionSubmit={(value) => {
        handleSelect(value);
      }}
      placeholder="Enter an address"
      disabled={!ready}
      {...form.getInputProps("address")}
    />
  );
};

const CenterTabContainer = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, // api key
    libraries,
  });

  const [selected, setSelected] = useState(null);

  const { harbourData, fetchHarbourList, setIsButtonDisabled, Clubs } =
    useCenterContext();

  const harbourDetails = harbourData;
  const { harbourDetailsEdit, setLoading, uploadImage, loading } =
    useCenterTabContext();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const [cancelFlag, setCancelFlag] = useState(false);

  useEffect(() => {
    if (file) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file]);

  useEffect(() => {
    setFileUrl(null);
  }, [harbourData?.harborId]);

  const isHarbourDataEmpty =
    !harbourDetails?.harborId || harbourDetails?.harborId.length === 0;

  const form = useForm({
    initialValues: {
      phoneNo: "",
      emergencyNo: "",
      email: "",
      address: "",
      harbor: "",
      pictureFile: null,
      name: "",
      clubType: "",
      companyName: "",
      vatNumber: "",

      address1: "",
      zipCode: "",
      city: "",
      country: null,
      highStartEndDate: [null, null],
      lowStartEndDate: [null, null],
      highSeasonAM: null,
      lowSeasonAM: null,
      timeZone: null,
      highSeasonPM: null,
      lowSeasonPM: null,
      currency: null,
      fuelPrice: "",
      fuelType: null,
      vat: null,
      electricPrice: "",
      engineHourPrice: "",
    },
    validate: {
      phoneNo: (Value) =>
        Value.length < 5 ? "Phone number is required" : null,
      emergencyNo: (Value) =>
        Value.length < 5 ? "Emergency number is required" : null,
      email: isEmail("Invalid email"),
      address: isNotEmpty("Address is required"),
      harbor: isNotEmpty("Harbor is required"),
      name: isNotEmpty("Name is required"),
      clubType: isNotEmpty("Club type is required"),
      companyName: isNotEmpty("Company name is required"),
      vatNumber: isNotEmpty("Vat number is required"),
      address1: isNotEmpty("Address is required"),
      zipCode: isNotEmpty("Zip code is required"),
      city: isNotEmpty("City is required"),
      country: isNotEmpty("Country is required"),
      highSeasonAM: isNotEmpty("High season AM is required"),
      lowSeasonAM: isNotEmpty("Low season AM is required"),
      timeZone: isNotEmpty("Time zone is required"),
      highSeasonPM: isNotEmpty("High season PM is required"),
      lowSeasonPM: isNotEmpty("Low season PM is required"),
      currency: isNotEmpty("Currency is required"),
      fuelPrice: isNotEmpty("Fuel price is required"),
      vat: isNotEmpty("Vat is required"),
      fuelType: isNotEmpty("Fuel type is required"),
      electricPrice: isNotEmpty("Electric price is required"),
      engineHourPrice: isNotEmpty("Engine hour price is required"),
      highStartEndDate: (value) =>
        value[0] ? null : "High season start to end date is required",
      lowStartEndDate: (value) =>
        value[0] ? null : "Low season start to end date is required",
    },
  });

  useEffect(() => {
    if (harbourDetails?.harborId) {
      form.setValues({
        phoneNo: harbourDetails?.phoneNo,
        emergencyNo: harbourDetails?.emergencyNumber,
        email: harbourDetails?.email,
        address: harbourDetails?.mapAddress,
        harbor: harbourDetails?.harborPresentation,
        pictureFile: harbourDetails?.pictureFile || BoatImagePlaceholder,
        name: harbourDetails?.harborName,
        clubType: harbourDetails?.clubType,
        companyName: harbourDetails?.fiscalCompanyName,
        vatNumber: harbourDetails?.vatNo,
        address1: harbourDetails?.companyAddress,
        zipCode: harbourDetails?.zip,
        city: harbourDetails?.city,
        country: harbourDetails?.country,
        highStartEndDate: [
          new Date(harbourDetails?.highSeasonStart),
          new Date(harbourDetails?.highSeasonEnd),
        ],
        lowStartEndDate: [
          new Date(harbourDetails?.lowSeasonStart),
          new Date(harbourDetails?.lowSeasonEnd),
        ],
        highSeasonAM: String(harbourDetails?.highSeasonAMHours),
        lowSeasonAM: String(harbourDetails?.lowSeasonAMHours),
        timeZone: String(harbourDetails?.timeZone),
        fuelPrice: String(harbourDetails?.fuelPrice),
        fuelType: harbourDetails?.fuelUnits,
        vat: String(harbourDetails?.vatLocal),
        currency: harbourDetails?.currency,
        highSeasonPM: String(harbourDetails?.highSeasonPMHours),
        lowSeasonPM: String(harbourDetails?.lowSeasonPMHours),
        electricPrice: harbourDetails?.electricPrice,
        engineHourPrice: harbourDetails?.engineHourPrice,
      });
    }
  }, [harbourDetails]);

  const [isEditing, setIsEditing] = useState(false);
  const { selectedClub } = useClubsContext();

  const newharbourDetails = {
    clubId: selectedClub?.clubId,
    regionId: harbourDetails.regionId,
    pictureFile: form.values.pictureFile,
    harborName: form.values.name,
    clubType: form.values.clubType,
    phoneNo: form.values.phoneNo,
    emergencyNumber: form.values.emergencyNo,
    email: form.values.email,
    mapAddress: form.values.address,
    harborPresentation: form.values.harbor,
    fiscalCompanyName: form.values.companyName,
    vatNo: form.values.vatNumber,
    companyAddress: form.values.address1,
    zip: form.values.zipCode,
    city: form.values.city,
    country: form.values.country,
    highSeasonStart: moment(form.values.highStartEndDate[0]).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    highSeasonEnd: moment(form.values.highStartEndDate[1]).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    lowSeasonStart: moment(form.values.lowStartEndDate[0]).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    lowSeasonEnd: moment(form.values.lowStartEndDate[1]).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    highSeasonAMHours: Number(form.values.highSeasonAM),
    highSeasonPMHours: Number(form.values.highSeasonPM),
    lowSeasonAMHours: Number(form.values.lowSeasonAM),
    lowSeasonPMHours: Number(form.values.lowSeasonPM),
    timeZone: form.values.timeZone,
    currency: form.values.currency,
    fuelPrice: Number(form.values.fuelPrice),
    fuelUnits: form.values.fuelType,
    vatLocal: Number(form.values.vat),
    electricPrice: Number(form.values.electricPrice),
    engineHourPrice: Number(form.values.engineHourPrice),
  };

  const handleSaveClick = async () => {
    if (file) {
      setLoading(true);
      uploadImage(file)
        .then(async (res) => {
          const harborLogo = res?.uploadedFileUrls[0];
          newharbourDetails.pictureFile = harborLogo;

          await harbourDetailsEdit(newharbourDetails, harbourData.harborId)
            .then((data) => {
              setLoading(false);
              Clubs();
              toast.success("Harbor data updated successfully!");
              setIsEditing(false);
              setIsButtonDisabled(false);
              fetchHarbourList(harbourDetails?.harborId);
              setFileUrl(null);
              setFile(null);
              return data;
            })
            .catch((error) => {
              setLoading(false);

              toast.error(error.message);
            });
        })
        .catch((e) => {
          toast.error("Image uploding failed");
          setLoading(false);
        });
    } else {
      await harbourDetailsEdit(newharbourDetails, harbourData.harborId)
        .then((data) => {
          setLoading(false);
          toast.success("Harbor data updated successfully!");
          setIsEditing(false);
          Clubs();
          setIsButtonDisabled(false);
          return data;
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    }
  };

  const handleCancelEdit = () => {
    setIsButtonDisabled(false);
    setIsEditing(false);
    form.setValues({
      phoneNo: harbourDetails?.phoneNo,
      emergencyNo: harbourDetails?.emergencyNumber,
      email: harbourDetails?.email,
      address: harbourDetails?.mapAddress,
      harbor: harbourDetails?.harborPresentation,
      pictureFile: harbourDetails?.pictureFile,
      name: harbourDetails?.harborName,
      clubType: harbourDetails?.clubType,
      companyName: harbourDetails?.fiscalCompanyName,
      vatNumber: harbourDetails?.vatNo,
      address1: harbourDetails?.companyAddress,
      zipCode: harbourDetails?.zip,
      city: harbourDetails?.city,
      country: harbourDetails?.country,
      highStartEndDate: [
        new Date(harbourDetails?.highSeasonStart || null),
        new Date(harbourDetails?.highSeasonEnd || null),
      ],
      lowStartEndDate: [
        new Date(harbourDetails?.lowSeasonStart || null),
        new Date(harbourDetails?.lowSeasonEnd || null),
      ],
      highSeasonAM: String(harbourDetails?.highSeasonAMHours),
      lowSeasonAM: String(harbourDetails?.lowSeasonAMHours),
      timeZone: String(harbourDetails?.timeZone),
      fuelPrice: String(harbourDetails?.fuelPrice),
      fuelType: harbourDetails?.fuelUnits,
      vat: String(harbourDetails?.vatLocal),
      currency: harbourDetails?.currency,
      highSeasonPM: String(harbourDetails?.highSeasonPMHours),
      lowSeasonPM: String(harbourDetails?.lowSeasonPMHours),
      electricPrice: harbourDetails?.electricPrice,
    });
    setFileUrl(null);
    setCancelFlag(true);
    setFile(null);
  };

  if (loadError)
    return (
      <Flex justify={"center"} w={"100%"} align={"center"} h={"70vh"}>
        <Loader />
      </Flex>
    );
  if (!isLoaded)
    return (
      <Flex justify={"center"} w={"100%"} align={"center"} h={"70vh"}>
        <Loader />
      </Flex>
    );

  return (
    <Flex>
      {loading ? (
        <Flex justify={"center"} w={"100%"} align={"center"} h={"70vh"}>
          <Loader />
        </Flex>
      ) : (
        <Box
          mt={10}
          w={"100%"}
          component="form"
          onSubmit={form.onSubmit(() => {
            if (form.isValid()) {
              handleSaveClick();
            }
          })}
        >
          <Flex justify="space-between" mb={8}>
            <Title order={3} ml={"lg"} mt={8} mb={8}>
              Center- {form.values.name}
            </Title>
            <Flex>
              {isEditing ? (
                <>
                  <Button onClick={handleCancelEdit}>Cancel</Button>
                  <Button variant="primary" ml={12} type="submit">
                    Save
                  </Button>
                </>
              ) : (
                <Button
                  size="sm"
                  onClick={() => {
                    setIsButtonDisabled(true);
                    setIsEditing(true);
                  }}
                  disabled={isHarbourDataEmpty}
                >
                  Edit
                </Button>
              )}
            </Flex>
          </Flex>
          <Flex justify={"space-around"}>
            <Flex justify="center" align={"center"} direction={"column"}>
              <Flex w={300} h={200} justify={"center"}>
                {fileUrl !== null ? (
                  <Image src={fileUrl} fit="fill" />
                ) : (
                  <Image src={form.values.pictureFile} fit="fill" />
                )}
              </Flex>

              {isEditing && (
                <ImageUploader
                  width={300}
                  height={200}
                  file={file}
                  setFile={setFile}
                  fileUrl={fileUrl}
                  setFileUrl={setFileUrl}
                />
              )}
            </Flex>
            <Flex direction={"column"} w={"40%"} rowGap={12}>
              <Flex justify={"space-between"} columnGap={10}>
                <Flex direction="column" rowGap={12}>
                  <Flex direction="column">
                    <Text size="sm" mb={4}>
                      Phone Number
                      <span style={{ color: "red" }}> *</span>
                    </Text>
                    <PhoneInput
                      defaultCountry="de"
                      inputStyle={{
                        borderTopRightRadius: "24px",
                        borderBottomRightRadius: "24px",
                        width: "100%",
                      }}
                      {...form.getInputProps("phoneNo")}
                      disabled={!isEditing}
                    />
                    {form.errors.phoneNo && (
                      <Text c="red" size="xs" mt={2}>
                        {form.errors.phoneNo}
                      </Text>
                    )}
                  </Flex>
                  <TextInput
                    label="Email"
                    withAsterisk
                    placeholder="John@gmail.com"
                    readOnly={!isEditing}
                    {...form.getInputProps("email")}
                  />
                </Flex>
                <Flex direction="column" rowGap={12}>
                  <Flex direction="column">
                    <Text size="sm" mb={4}>
                      Emergency Number
                      <span style={{ color: "red" }}> *</span>
                    </Text>
                    <PhoneInput
                      defaultCountry="de"
                      inputStyle={{
                        borderTopRightRadius: "24px",
                        borderBottomRightRadius: "24px",
                        width: "100%",
                      }}
                      {...form.getInputProps("emergencyNo")}
                      disabled={!isEditing}
                    />
                    {form.errors.emergencyNo && (
                      <Text c="red" size="xs" mt={2}>
                        {form.errors.emergencyNo}
                      </Text>
                    )}
                  </Flex>
                  <MapSearch
                    setSelected={setSelected}
                    readOnly={!isEditing}
                    cancelFlag={cancelFlag}
                    setCancelFlag={setCancelFlag}
                    form={form}
                  />
                </Flex>
              </Flex>
              <TextInput
                label="Harbour Presentation"
                placeholder="Harbour Presentation"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("harbor")}
              />
            </Flex>
            <Flex align={"center"}>
              <Flex w={"100%"}>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={10}
                  center={selected ? selected : center}
                >
                  {selected && <Marker position={selected} />}
                </GoogleMap>
              </Flex>
            </Flex>
          </Flex>
          <Divider my="xl" />
          <Group grow align="flex-start" justify="space-around" px={24}>
            <Flex direction="column" rowGap={12}>
              <TextInput
                label="Name"
                placeholder="Name"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("name")}
              />
              <Select
                label="Type"
                withAsterisk
                placeholder="Club Type"
                data={CLUB_TYPE}
                clearable
                readOnly={!isEditing}
                {...form.getInputProps("clubType")}
              />
              <TextInput
                label="Fiscal company name of boat club"
                placeholder="Fiscal company name of boat club"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("companyName")}
              />
              <TextInput
                label="VAT number company"
                placeholder="VAT number company"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("vatNumber")}
              />
              <TextInput
                label="Address"
                withAsterisk
                placeholder="Address"
                readOnly={!isEditing}
                {...form.getInputProps("address1")}
              />
              <TextInput
                label="Zip Code"
                placeholder="Zip Code"
                withAsterisk
                hideControls
                readOnly={!isEditing}
                allowDecimal={false}
                {...form.getInputProps("zipCode")}
              />
            </Flex>
            <Flex direction="column" rowGap={12}>
              <TextInput
                label="City"
                placeholder="City"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("city")}
              />
              <Select
                label="Country"
                placeholder="Country"
                withAsterisk
                data={CountryList}
                searchable
                clearable
                readOnly={!isEditing}
                {...form.getInputProps("country")}
              />
              <DatePickerInput
                clearable
                leftSectionPointerEvents="none"
                withAsterisk
                label="High Season Start To End"
                placeholder=" DD/MM/YYYY - DD/MM/YYYY"
                type="range"
                readOnly={!isEditing}
                {...form.getInputProps("highStartEndDate")}
              />
              <DatePickerInput
                clearable
                label="Low Season Start To End"
                withAsterisk
                placeholder=" DD/MM/YYYY - DD/MM/YYYY"
                type="range"
                readOnly={!isEditing}
                {...form.getInputProps("lowStartEndDate")}
              />
              <Select
                label="Time Zone"
                placeholder="Select Location"
                withAsterisk
                searchable
                data={Timezones_List.map((id) => ({
                  label: id.value,
                  value: id.javaZoneId,
                }))}
                readOnly={!isEditing}
                {...form.getInputProps("timeZone")}
              />
            </Flex>

            <Flex direction="column" justify="flex-start">
              <Flex
                direction={{
                  base: "column",
                  lg: "row",
                  xl: "row",
                }}
              >
                <Flex direction={"column"} rowGap={12}>
                  <NumberInput
                    label="Fuel Price"
                    placeholder="00"
                    withAsterisk
                    hideControls
                    readOnly={!isEditing}
                    min={0}
                    decimalScale={2}
                    {...form.getInputProps("fuelPrice")}
                  />
                  <Select
                    label="VAT %"
                    placeholder="VAT %"
                    withAsterisk
                    data={Vat}
                    readOnly={!isEditing}
                    {...form.getInputProps("vat")}
                  />
                  <Select
                    label="High Season Start Hours"
                    placeholder="00"
                    withAsterisk
                    data={Hours}
                    readOnly={!isEditing}
                    {...form.getInputProps("highSeasonAM")}
                  />
                  <Select
                    label="Low Season Start Hours"
                    placeholder="00"
                    withAsterisk
                    data={Hours}
                    readOnly={!isEditing}
                    {...form.getInputProps("lowSeasonAM")}
                  />
                  <NumberInput
                    label="Engine Hours Price"
                    placeholder="00"
                    withAsterisk
                    hideControls
                    readOnly={!isEditing}
                    min={0}
                    decimalScale={2}
                    {...form.getInputProps("engineHourPrice")}
                  />
                </Flex>
                <Flex direction={"column"} ml={12} rowGap={12}>
                  <Select
                    label="Currency"
                    placeholder="Є"
                    withAsterisk
                    data={Currencies.map((id) => id.name)}
                    readOnly={!isEditing}
                    {...form.getInputProps("currency")}
                  />
                  <Select
                    label="Fuel Units"
                    placeholder="Litre"
                    withAsterisk
                    data={FUEL_TYPE}
                    readOnly={!isEditing}
                    {...form.getInputProps("fuelType")}
                  />
                  <Select
                    label="High Season End Hours"
                    placeholder="00"
                    withAsterisk
                    data={Hours}
                    readOnly={!isEditing}
                    {...form.getInputProps("highSeasonPM")}
                  />
                  <Select
                    label="Low Season End Hours"
                    withAsterisk
                    placeholder="00"
                    data={Hours}
                    readOnly={!isEditing}
                    {...form.getInputProps("lowSeasonPM")}
                  />
                  <NumberInput
                    label="Electric Price"
                    placeholder="00 Є"
                    withAsterisk
                    hideControls
                    readOnly={!isEditing}
                    min={0}
                    decimalScale={2}
                    {...form.getInputProps("electricPrice")}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Group>
        </Box>
      )}
    </Flex>
  );
};

const CenterTab = () => {
  return (
    <CenterTabContextProvider>
      <CenterTabContainer />
    </CenterTabContextProvider>
  );
};

export default CenterTab;
