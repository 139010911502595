import { createContext, useContext, useEffect, useState } from "react";
// import { ClubSwitchSubject } from "shared/Subjects";
import {
  APIClubsResponseClub,
  TransformedClubRegion,
} from "shared/model/API/api.region.model";
import { getClubsByCurrentUser } from "shared/utils/API/regions.api";
import { useUserContext } from "../UserContextProvider/UserContextProvider";
import { CURRENCY_DATA, USER_ROLE } from "shared/Constants/general.const";
import { Storage } from "shared/utils/Storage";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "shared/Constants/routes.const";

declare global {
  interface Window {
    mox: any;
  }
}
const ClubsContext = createContext<{
  clubs: APIClubsResponseClub[];
  regions: TransformedClubRegion[];
  selectedClub: APIClubsResponseClub | null;
  setSelectedClub: React.Dispatch<
    React.SetStateAction<APIClubsResponseClub | null>
  >;
  setRegionId: React.Dispatch<React.SetStateAction<any>>; // <-- Add this line
  setHarborId: React.Dispatch<React.SetStateAction<any>>;
  regionId: any; // <-- Add this line
  harborId: any;
  setHarborList: React.Dispatch<React.SetStateAction<any[]>>;
  setRegionList: React.Dispatch<React.SetStateAction<any[]>>;
  setBoatIds: React.Dispatch<React.SetStateAction<any[]>>;
  harborList: any[];
  regionList: any[];
  boatIds: any[];
  setCenterClubFlag: React.Dispatch<React.SetStateAction<boolean>>;
  centerClubFlag: boolean;
}>({
  clubs: [],
  regions: [],
  selectedClub: null,
  setRegionId: () => {},
  setHarborId: () => {},
  setSelectedClub: () => {},
  regionId: null, // <-- Initialize with a default value
  harborId: null,
  setHarborList: () => {},
  setRegionList: () => {},
  setBoatIds: () => {},
  harborList: [],
  regionList: [],
  boatIds: [],
  setCenterClubFlag: () => {},
  centerClubFlag: false,
});

const ClubsContextProvider = ({ children }: { children: any }) => {
  const [clubs, setClubs] = useState<APIClubsResponseClub[]>([]);
  const [regions, setRegions] = useState<TransformedClubRegion[]>([]);
  const [regionId, setRegionId] = useState(null);
  const [harborId, setHarborId] = useState<any>(null);
  const [harborList, setHarborList] = useState<any[]>([]);
  const [regionList, setRegionList] = useState<any[]>([]);
  const [boatIds, setBoatIds] = useState<any[]>([]);
  const [centerClubFlag, setCenterClubFlag] = useState(false);

  const [selectedClub, setSelectedClub] = useState<APIClubsResponseClub | null>(
    null
  );

  const navigate = useNavigate();

  const { userPermissions } = useUserContext();
  useEffect(() => {
    const clubIdTemp = Storage.get(Storage.KEYS.CLUB_ID);
    let selectedClubTemp: any;

    // Helper function to find and append the currency symbol
    const appendCurrencySymbol = (club: any) => {
      const currencySymbol: string | undefined = CURRENCY_DATA?.find(
        (cur) => cur.currencyCode === club?.defaultCurrency
      )?.currencySymbol;
      if (club) {
        club.currencySymbol = currencySymbol as string; // Type assertion
      }
      return club;
    };

    if (clubIdTemp) {
      selectedClubTemp = clubs.find((club) => club.clubId === clubIdTemp);
      if (selectedClubTemp) {
        selectedClubTemp = appendCurrencySymbol(selectedClubTemp);
      }
    } else {
      if (userPermissions?.roleName === USER_ROLE.Clubmanager.value) {
        selectedClubTemp = appendCurrencySymbol(clubs[0]);
      } else if (userPermissions?.roleName === USER_ROLE.Clubworker.value) {
        selectedClubTemp = appendCurrencySymbol(clubs[0]);
      } else {
        selectedClubTemp = clubs.find((club) => club.isClubOnBoarded);
        if (selectedClubTemp) {
          selectedClubTemp = appendCurrencySymbol(selectedClubTemp);
        }
      }

      if (selectedClubTemp) {
        Storage.set(Storage.KEYS.CLUB_ID, selectedClubTemp.clubId);
      }
    }

    setSelectedClub(selectedClubTemp);
    navigate(ROUTE_PATHS.DASHBOARD);
  }, [clubs]);

  useEffect(() => {
    getClubsByCurrentUser().then((clubs) => setClubs(clubs));
  }, []);

  useEffect(() => {
    const regionsTransformed: TransformedClubRegion[] = clubs
      .map((club) =>
        club.regions
          .filter((region) => region?.harbors?.length > 0)
          .map((region) => ({
            regionId: region.regionId,
            regionName: region.regionName,
            clubId: club.clubId,
            harbors: region.harbors.map((harbour) => ({
              clubId: club.clubId,
              name: harbour.harborName,
              id: harbour.harborId,
            })),
          }))
      )
      .flat();
    setRegions(regionsTransformed);
  }, [clubs]);

  return (
    <ClubsContext.Provider
      value={{
        clubs,
        regions,
        selectedClub,
        setSelectedClub,
        setHarborId,
        setRegionId,
        harborId,
        regionId,
        setHarborList,
        setRegionList,
        setBoatIds,
        harborList,
        regionList,
        boatIds,
        setCenterClubFlag,
        centerClubFlag,
      }}
    >
      {children}
    </ClubsContext.Provider>
  );
};

export default ClubsContextProvider;

export const withClubsContext = (Component: any) => (props: any) =>
  (
    <ClubsContextProvider>
      <Component {...props} />
    </ClubsContextProvider>
  );

export const useClubsContext = () => {
  return useContext(ClubsContext);
};
