import {
  Box,
  Button,
  Flex,
  Image,
  Select,
  Text,
  TextInput,
  Title,
  Loader,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { FileToDataUrl } from "shared/utils/file";
import placeholder from "../../../../../assets/images/placeholder.png";
import {
  ClubSettingContextProvider,
  useClubSettingContext,
} from "./ClubSetting.context";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { CountryList } from "shared/Constants/general.const";
import toast from "react-hot-toast";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";
import { PhoneInput } from "react-international-phone";
import ImageUploader from "Components/Atoms/ImageUploader";
import { useClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";

const ClubSettingContainer = () => {
  const {
    clubDetails,
    editClubDetails,
    uploadImage,
    loading,
    setLoading,
    fetchClubDetails,
  } = useClubSettingContext();
  const { userDetails } = useUserContext();
  const { selectedClub } = useClubsContext();

  const clubDetailsList = clubDetails[0];
  const form = useForm({
    initialValues: {
      clubName: clubDetailsList?.clubName || "",
      clubLogo: clubDetailsList?.clubLogo || "",
      clubFirstName: clubDetailsList?.clubContactFirstName || "",
      clubLastName: clubDetailsList?.clubContactLastName || "",
      clubStreetAddress: clubDetailsList?.clubStreetAddress || "",
      clubZipCode: clubDetailsList?.clubPostcode || "",
      clubCity: clubDetailsList?.clubCity || "",
      clubCountry: clubDetailsList?.clubCountry || "",
      clubPhone: clubDetailsList?.clubPhone || "",
      clubEmail: clubDetailsList?.clubEmail || "",
      clubVat: clubDetailsList?.clubVAT || "",
      clubManagerId: clubDetailsList?.clubManagerId || "",
      commission: String(clubDetailsList?.clubMoxseaCommissionPercent),
      companyName: clubDetailsList?.companyName || "",
      vatType: clubDetailsList?.vatType || "",
      defaultCurrency: clubDetailsList?.defaultCurrency || "",
    },
    validate: {
      clubName: isNotEmpty("Please enter club name"),
      clubFirstName: isNotEmpty("Please enter club first name"),
      clubLastName: isNotEmpty("Please enter club last name"),
      clubStreetAddress: isNotEmpty("Please enter club street address"),
      clubZipCode: isNotEmpty("Please enter club zip code"),
      clubCity: isNotEmpty("Please enter club city"),
      clubCountry: isNotEmpty("Please enter club country"),
      clubPhone: (Value) =>
        Value.length < 5 ? "Please enter club phone" : null,
      clubEmail: isEmail("Please enter club email"),
      clubVat: isNotEmpty("Please enter club vat"),
      commission: isNotEmpty("Please enter commission"),
      companyName: isNotEmpty("Please enter company name"),
    },
  });

  useEffect(() => {
    if (clubDetailsList) {
      form.setValues({
        clubName: clubDetailsList?.clubName || "",
        clubLogo: clubDetailsList?.clubLogo || "",
        clubFirstName: clubDetailsList?.clubContactFirstName || "",
        clubLastName: clubDetailsList?.clubContactLastName || "",
        clubStreetAddress: clubDetailsList?.clubStreetAddress || "",
        clubZipCode: clubDetailsList?.clubPostcode || "",
        clubCity: clubDetailsList?.clubCity || "",
        clubCountry: clubDetailsList?.clubCountry || null,
        clubPhone: clubDetailsList?.clubPhone || "",
        clubEmail: clubDetailsList?.clubEmail || "",
        clubVat: clubDetailsList?.clubVAT || "",
        clubManagerId: clubDetailsList?.clubManagerId || "",
        commission: String(clubDetailsList?.clubMoxseaCommissionPercent),
        companyName: clubDetailsList?.companyName || "",
        vatType: clubDetailsList?.vatType || "",
        defaultCurrency: clubDetailsList?.defaultCurrency || "",
      });
    }
  }, [clubDetailsList]);
  const [isEditing, setIsEditing] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    if (file) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file]);

  const handleCancelClick = () => {
    form.setValues({
      clubName: clubDetailsList?.clubName || "",
      clubLogo: clubDetailsList?.clubLogo || "",
      clubFirstName: clubDetailsList?.clubContactFirstName || "",
      clubLastName: clubDetailsList?.clubContactLastName || "",
      clubStreetAddress: clubDetailsList?.clubStreetAddress || "",
      clubZipCode: clubDetailsList?.clubPostcode || "",
      clubCity: clubDetailsList?.clubCity || "",
      clubCountry: clubDetailsList?.clubCountry || "",
      clubPhone: clubDetailsList?.clubPhone || "",
      clubEmail: clubDetailsList?.clubEmail || "",
      clubVat: clubDetailsList?.clubVAT || "",
      commission: String(clubDetailsList?.clubMoxseaCommissionPercent || ""),
    });
    // setFileUrl(clubDetailsList?.clubLogo);
    setIsEditing(false);
    setFileUrl(null);
    setFile(null);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const saveClubDetails = () => {
    setLoading(true);
    const clubId = clubDetailsList?.id;
    const newClubDetails = {
      clubName: form.values.clubName,
      clubLogo: form.values.clubLogo,
      clubManagerId: form.values.clubManagerId,
      clubContactFirstName: form.values.clubFirstName,
      clubContactLastName: form.values.clubLastName,
      clubStreetAddress: form.values.clubStreetAddress,
      clubPostcode: form.values.clubZipCode,
      clubCity: form.values.clubCity,
      clubCountry: form.values.clubCountry,
      clubPhone: form.values.clubPhone,
      clubEmail: form.values.clubEmail,
      clubVAT: form.values.clubVat,
      clubMoxseaCommissionPercent: String(form.values.commission),
      companyName: form.values.companyName,
      vatType: form.values.vatType,
    };

    if (file) {
      uploadImage(file, clubId)
        .then((res) => {
          const newLogo = res?.uploadedFileUrls[0];
          if (newLogo) {
            newClubDetails.clubLogo = newLogo;
          } else {
            newClubDetails.clubLogo = form.values.clubLogo;
          }

          editClubDetails(newClubDetails, clubId)
            .then(() => {
              toast.success("Club details updated successfully!");
              setIsEditing(false);
              // form.reset();
              setLoading(false);
              fetchClubDetails(selectedClub?.clubId);
              setFileUrl(null);
              setFile(null);
            })
            .catch((error) => {
              toast.success(error.message || "Save failed. Please try again.");
              setLoading(false);
            });
        })
        .catch((error) => {
          toast.error(error.message || "Save failed. Please try again.");
          setLoading(false);
        });
    } else {
      editClubDetails(newClubDetails, clubId)
        .then(() => {
          toast.success("Club details updated successfully!");
          setIsEditing(false);
          setLoading(false);
          setFileUrl(null);
          setFile(null);
          fetchClubDetails(selectedClub?.clubId);
        })
        .catch((error) => {
          toast.error(error.message || "Save failed. Please try again.");
          setLoading(false);
        });
    }
  };

  return (
    <Flex direction="column" gap="md" px={"md"}>
      {loading ? (
        <Flex justify={"center"} h={"70vh"} align={"center"}>
          <Loader />
        </Flex>
      ) : (
        <Box
          component="form"
          onSubmit={form.onSubmit((values) => {
            if (form.isValid()) {
              saveClubDetails();
            }
          })}
        >
          <Flex align={"center"} justify={"space-between"} mb="md">
            <Title order={3}>Club Details</Title>
            {isEditing ? (
              <Flex mt="md" justify="space-between" columnGap={12}>
                <Button
                  onClick={() => {
                    handleCancelClick();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Flex>
            ) : (
              <Flex mt="md">
                <Button
                  onClick={() => {
                    handleEditClick();
                  }}
                >
                  Edit
                </Button>
              </Flex>
            )}
          </Flex>
          <Flex w={"100%"} columnGap={18}>
            <Flex direction="column" w={"50%"} rowGap={12}>
              <TextInput
                withAsterisk
                readOnly={!isEditing}
                label="Club Name"
                placeholder="Club Name"
                {...form.getInputProps("clubName")}
              />
              <TextInput
                label="Club Manager Name"
                placeholder="Club Manager Name"
                readOnly
                value={userDetails?.firstName + " " + userDetails?.lastName}
              />
              <TextInput
                label="Fiscal Company Name"
                placeholder="Fiscal Company Name"
                readOnly={!isEditing}
                withAsterisk
                {...form.getInputProps("companyName")}
              />
              <TextInput
                label="Club Contact First Name"
                placeholder="Club Contact First Name"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("clubFirstName")}
              />
              <TextInput
                label="Club Contact Last Name"
                placeholder="Club Last Name"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("clubLastName")}
              />
              <Flex direction="column">
                <Text size="sm" mb={4} fw={500}>
                  Phone
                  <span style={{ color: "red" }}> *</span>
                </Text>

                <PhoneInput
                  defaultCountry="de"
                  inputStyle={{
                    borderTopRightRadius: "24px",
                    borderBottomRightRadius: "24px",
                    width: "100%",
                  }}
                  disabled={!isEditing}
                  {...form.getInputProps("clubPhone")}
                />
                {form.errors.clubPhone && (
                  <Text c="red" size="xs" mt={2}>
                    {form.errors.clubPhone}
                  </Text>
                )}
              </Flex>
              <TextInput
                label="Club Email"
                withAsterisk
                placeholder="Club Email"
                readOnly={!isEditing}
                {...form.getInputProps("clubEmail")}
              />
              <TextInput
                label="Club Street Address"
                placeholder="Club Street Address"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("clubStreetAddress")}
              />
              <TextInput
                label="Club PostCode"
                placeholder="Club PostCode"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("clubZipCode")}
              />
            </Flex>
            <Flex
              direction="column"
              w={"50%"}
              justify={"space-between"}
              rowGap={12}
            >
              <Flex direction="column">
                <Text mt={4} fw="600">
                  Club Logo <span style={{ color: "#fb7852" }}>*</span>
                </Text>
                <Flex justify="center">
                  {fileUrl ? (
                    <Image
                      fit="contain"
                      w={180}
                      h={120}
                      src={fileUrl || placeholder}
                      style={{ border: "1px solid #cdcaca" }}
                    />
                  ) : (
                    <Image
                      fit="contain"
                      w={180}
                      h={120}
                      src={form.values.clubLogo || placeholder}
                      style={{ border: "1px solid #cdcaca" }}
                    />
                  )}
                </Flex>

                {isEditing && (
                  <ImageUploader
                    fileUrl={fileUrl}
                    setFile={setFile}
                    setFileUrl={setFileUrl}
                    file={file}
                  />
                )}
              </Flex>
              <Flex rowGap="12" direction="column">
                <TextInput
                  label="Club City"
                  placeholder="Club City"
                  withAsterisk
                  readOnly={!isEditing}
                  {...form.getInputProps("clubCity")}
                />
                <Select
                  label="Club Country"
                  withAsterisk
                  placeholder="Club Country"
                  searchable
                  clearable
                  disabled
                  data={CountryList}
                  {...form.getInputProps("clubCountry")}
                />
                <TextInput
                  label="VAT Type"
                  withAsterisk
                  disabled
                  placeholder="VAT Type"
                  {...form.getInputProps("vatType")}
                />
                <TextInput
                  label="Default Currency"
                  withAsterisk
                  placeholder="Default Currency"
                  disabled
                  {...form.getInputProps("defaultCurrency")}
                />
                <TextInput
                  label="Club VAT Number"
                  withAsterisk
                  placeholder="Club VAT Number"
                  disabled
                  {...form.getInputProps("clubVat")}
                />
                <Select
                  label="Clubs MOXSEA commission %"
                  data={Array.from({ length: 101 }, (_, i) => `${i}`)}
                  withAsterisk
                  readOnly={!isEditing}
                  placeholder="Club VAT Number"
                  {...form.getInputProps("commission")}
                />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

const ClubSetting = () => {
  return (
    <ClubSettingContextProvider>
      <ClubSettingContainer />
    </ClubSettingContextProvider>
  );
};
export default ClubSetting;
