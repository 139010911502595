import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { BoatFleetList } from "./BoatFleetTab.model";
import { BoatResponseDetailed } from "shared/model/API/api.boat.model";

const BoatFleetContext = createContext<{
  boatFleet: BoatFleetList;
  onSearch: (value: string) => void;
}>({
  boatFleet: [],
  onSearch: (_value: string) => {},
});

const useBoatFleet = () => {
  const [boatFleet, setBoatFleet] = useState<BoatResponseDetailed[] | null>(
    null
  );

  const [displayBoatFleet, setDisplayBoatFleet] =
    useState<BoatFleetList>(boatFleet);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filterBoatFleet = useCallback(() => {
    /**
     * If Search string is empty do not spend computation on filtering the array with an empty string.
     */
    if (!searchTerm) return boatFleet;
    const filteredBoatFleet = boatFleet?.filter((boat) =>
      `${boat.boatName} ${boat.boatModel}`
        ?.toLowerCase()
        .replaceAll(" ", "")
        .includes(searchTerm?.replaceAll(" ", "").toLowerCase())
    );
    return filteredBoatFleet?.length ? filteredBoatFleet : "NO_RESULTS_FOUND";
  }, [boatFleet, searchTerm]);

  useEffect(() => {
    const filteredBoatFleet: BoatFleetList = filterBoatFleet();
    setDisplayBoatFleet(filteredBoatFleet);
  }, [filterBoatFleet]);

  return { boatFleet: displayBoatFleet, onSearch };
};

export const BoatFleetProvider = ({ children }: { children: any }) => {
  const { boatFleet, onSearch } = useBoatFleet();
  return (
    <BoatFleetContext.Provider value={{ boatFleet, onSearch }}>
      {children}
    </BoatFleetContext.Provider>
  );
};

export const useBoatFleetContext = () => {
  return useContext(BoatFleetContext);
};

export const withBoatFleetContext = (Component: any) => {
  return (props: any) => (
    <BoatFleetProvider>
      <Component {...props} />
    </BoatFleetProvider>
  );
};
