import { createContext, useContext, useState, useEffect } from "react";
import { API_URLS } from "shared/Constants/api.const";
import http from "shared/utils/http";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";
import { S3_FOLDER_NAME } from "shared/Constants/general.const";

const ClubManagementContext = createContext({
  userData: [],
  fetchUsersList: () => {},
  loading: true,
  addClub: () => {},
  uploadImage: () => {},
  clubDetails: [],
  fetchClubDetails: () => {},
  editClubDetails: () => {},
  fetchUserDetails: () => {},
  userDetailsData: [],
  clubsList: [],
  addClubLoading: false,
  setAddClubLoading: () => {},
  deactivateClub: () => {},
  activateClub: () => {},
});

export const ClubManagementnContextProvider = ({ children }) => {
  const { userDetails } = useUserContext();
  const userId = userDetails?.userId;
  const [userData, setUserData] = useState([]);
  const [clubDetails, setClubDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetailsData, setUserDetailsData] = useState([]);
  const [clubsList, setClubsList] = useState([]);
  const [addClubLoading, setAddClubLoading] = useState(false);

  const fetchUsersList = async (userId) => {
    const response = await http
      .get(`${API_URLS.USER_CLUBS_DETAILS}?userId=${userId}`)
      .then((data) => data)
      .catch((error) => {});
    setUserData(response?.clubs || []);
    const clubNamesAll = response?.clubs?.map((club) => {
      return {
        clubName: club.clubName,
        clubId: club.id,
      };
    });
    setClubsList(clubNamesAll);
    setLoading(false);
  };

  const fetchUserDetails = async (userid) => {
    const response = await http
      .get(`${API_URLS.GET_USER_LIST_DETAILS.replace(":user-id", userid)}`)
      .then((data) => data)
      .catch((error) => {});

    setUserDetailsData(response || []);

    return response || [];
  };
  const addClub = async (data) => {
    setAddClubLoading(true);
    await http.post(API_URLS.ADD_CLUB, data);
    fetchUsersList(userId);
  };

  const uploadImage = async (imageFile) => {
    const folderName = S3_FOLDER_NAME.CLUB_LOGO;
    const formData = new FormData();
    formData.append("files", imageFile);
    let res;

    res = await http.postFormData(
      `${API_URLS.UPLOAD_IMAGE}${folderName}`,
      formData
    );

    return res;
  };

  const fetchClubDetails = async (clubId) => {
    const response = await http
      .get(`${API_URLS.CLUB_DETAILS.replace(":club-id", clubId)}`)
      .then((data) => data)
      .catch((error) => {});
    setClubDetails(response?.clubs || []);
    return response;
  };

  const editClubDetails = async (newClubDetails, clubId) => {
    await http.put(
      `${API_URLS.EDIT_CLUB_DETAILS.replace(":clubId", clubId)}`,
      newClubDetails
    );
    fetchUsersList(userId);
  };

  const deactivateClub = async (clubId) => {
    setLoading(true);
    await http.patch(`${API_URLS.DEACTIVATE_CLUB.replace(":clubId", clubId)}`);
    fetchUsersList(userId);
  };
  const activateClub = async (clubId) => {
    setLoading(true);
    await http.patch(`${API_URLS.ACTIVATE_CLUB.replace(":clubId", clubId)}`);
    fetchUsersList(userId);
  };

  useEffect(() => {
    fetchUsersList(userId);
  }, [userId]);

  return (
    <ClubManagementContext.Provider
      value={{
        userData,
        fetchUsersList,
        loading,
        addClub,
        fetchClubDetails,
        clubDetails,
        uploadImage,
        editClubDetails,
        userDetailsData,
        clubsList,
        fetchUserDetails,
        addClubLoading,
        setAddClubLoading,
        deactivateClub,
        activateClub,
      }}
    >
      {children}
    </ClubManagementContext.Provider>
  );
};

export const useClubManagementContext = () => useContext(ClubManagementContext);
