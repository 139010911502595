import {
  Box,
  Button,
  Flex,
  Image,
  Loader,
  Select,
  Text,
  TextInput,
} from "@mantine/core";

import { useEffect, useState } from "react";
import { FileToDataUrl } from "shared/utils/file";
import placeholder from "../../../../../../../assets/images/placeholder.png";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { useClubManagementContext } from "../../ClubManagement.context";
import toast from "react-hot-toast";
import { CountryList } from "shared/Constants/general.const";
import { PhoneInput } from "react-international-phone";
import ImageUploader from "Components/Atoms/ImageUploader";
const ViewClubs = ({ closeHandler, clubManager }) => {
  const { clubDetails, editClubDetails, uploadImage } =
    useClubManagementContext();
  const [file, setFile] = useState(null);

  const [fileUrl, setFileUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const clubDetailsList = clubDetails[0];

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    form.values.clubName = clubDetailsList?.clubName || "";
    form.values.clubLogo = clubDetailsList?.clubLogo || "";
    form.values.clubFirstName = clubDetailsList?.clubContactFirstName || "";
    form.values.clubLastName = clubDetailsList?.clubContactLastName || "";
    form.values.clubStreetAddress = clubDetailsList?.clubStreetAddress || "";
    form.values.clubZipCode = clubDetailsList?.clubPostcode || "";
    form.values.clubCity = clubDetailsList?.clubCity || "";
    form.values.clubCountry = clubDetailsList?.clubCountry || "";
    form.values.clubPhone = clubDetailsList?.clubPhone || "";
    form.values.clubEmail = clubDetailsList?.clubEmail || "";
    form.values.clubVat = clubDetailsList?.clubVAT || "";
    form.values.commission = String(
      clubDetailsList?.clubMoxseaCommissionPercent
    );
    setIsEditing(false);
  };

  useEffect(() => {
    if (file) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file]);

  const form = useForm({
    initialValues: {
      clubName: clubDetailsList?.clubName || "",
      clubLogo: clubDetailsList?.clubLogo || "",
      clubFirstName: clubDetailsList?.clubContactFirstName || "",
      clubLastName: clubDetailsList?.clubContactLastName || "",
      clubStreetAddress: clubDetailsList?.clubStreetAddress || "",
      clubZipCode: clubDetailsList?.clubPostcode || "",
      clubCity: clubDetailsList?.clubCity || "",
      clubCountry: clubDetailsList?.clubCountry || "",
      clubPhone: clubDetailsList?.clubPhone || "",
      clubEmail: clubDetailsList?.clubEmail || "",
      clubVat: clubDetailsList?.clubVAT || "",
      clubManagerId: clubDetailsList?.clubManagerId || "",
      commission: String(clubDetailsList?.clubMoxseaCommissionPercent) || "",
      companyName: clubDetailsList?.companyName || "",
      defaultCurrency: clubDetailsList?.defaultCurrency || "",
      vatType: clubDetailsList?.vatType || "",
    },
    validate: {
      clubName: isNotEmpty("Please enter club name"),
      clubFirstName: isNotEmpty("Please enter club first name"),
      clubLastName: isNotEmpty("Please enter club last name"),
      clubStreetAddress: isNotEmpty("Please enter club street address"),
      clubZipCode: isNotEmpty("Please enter club zip code"),
      clubCity: isNotEmpty("Please enter club city"),
      clubCountry: isNotEmpty("Please enter club country"),
      clubPhone: (Value) =>
        Value.length < 5 ? "Please enter club phone" : null,
      clubEmail: isEmail("Please enter club email"),
      clubVat: isNotEmpty("Please enter club vat"),
      commission: isNotEmpty("Please enter commission"),
      companyName: isNotEmpty("Please enter company name"),
    },
  });

  useEffect(() => {
    if (clubDetailsList) {
      form.setValues({
        clubName: clubDetailsList?.clubName || "",
        clubLogo: clubDetailsList?.clubLogo || "",
        clubFirstName: clubDetailsList?.clubContactFirstName || "",
        clubLastName: clubDetailsList?.clubContactLastName || "",
        clubStreetAddress: clubDetailsList?.clubStreetAddress || "",
        clubZipCode: clubDetailsList?.clubPostcode || "",
        clubCity: clubDetailsList?.clubCity || "",
        clubCountry: clubDetailsList?.clubCountry || null,
        clubPhone: clubDetailsList?.clubPhone || "",
        clubEmail: clubDetailsList?.clubEmail || "",
        clubVat: clubDetailsList?.clubVAT || "",
        clubManagerId: clubDetailsList?.clubManagerId || "",
        commission: String(clubDetailsList?.clubMoxseaCommissionPercent) || "",
        companyName: clubDetailsList?.companyName || "",
        defaultCurrency: clubDetailsList?.defaultCurrency || "",
        vatType: clubDetailsList?.vatType || "",
      });
    }
  }, [clubDetailsList]);

  const saveClubDetails = () => {
    setIsLoading(true);
    const clubId = clubDetailsList?.id;
    const newClubDetails = {
      clubName: form.values.clubName,
      clubLogo: form.values.clubLogo,
      clubManagerId: form.values.clubManagerId,
      clubContactFirstName: form.values.clubFirstName,
      clubContactLastName: form.values.clubLastName,
      clubStreetAddress: form.values.clubStreetAddress,
      clubPostcode: form.values.clubZipCode,
      clubCity: form.values.clubCity,
      clubCountry: form.values.clubCountry,
      clubPhone: form.values.clubPhone,
      clubEmail: form.values.clubEmail,
      clubVAT: form.values.clubVat,
      clubMoxseaCommissionPercent: Number(form.values.commission),
      companyName: form.values.companyName,
      vatType: form.values.vatType,
    };

    if (file) {
      uploadImage(file, clubId)
        .then((res) => {
          const newLogo = res?.uploadedFileUrls[0];
          if (newLogo) {
            newClubDetails.clubLogo = newLogo;
          } else {
            newClubDetails.clubLogo = form.values.clubLogo;
          }

          editClubDetails(newClubDetails, clubId)
            .then(() => {
              toast.success("Club details updated successfully!");
              closeHandler();
              form.reset();
              setIsLoading(false);
            })
            .catch((error) => {
              toast.error(error.message || "Save failed. Please try again.");
              setIsLoading(false);
            });
        })
        .catch((error) => {
          toast.error(error.message || "Save failed. Please try again.");
          setIsLoading(false);
        });
    } else {
      editClubDetails(newClubDetails, clubId)
        .then(() => {
          toast.success("Club details updated successfully!");
          closeHandler();
          form.reset();
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error.message || "Save failed. Please try again.");
          setIsLoading(false);
        });
    }
  };

  return (
    <Flex direction="column" gap="md">
      {isLoading ? (
        <Flex justify={"center"} align={"center"} h={"70vh"}>
          <Loader />
        </Flex>
      ) : (
        <Box
          component="form"
          onSubmit={form.onSubmit((values) => {
            if (form.isValid()) {
              saveClubDetails();
            }
          })}
        >
          <Flex direction="column" rowGap={12}>
            <TextInput
              withAsterisk
              readOnly={!isEditing}
              label="Club Name"
              placeholder="Club Name"
              {...form.getInputProps("clubName")}
            />
            <TextInput
              withAsterisk
              label="Fiscal Company Name"
              placeholder="Fiscal Company Name"
              readOnly={!isEditing}
              {...form.getInputProps("companyName")}
            />
            <Flex direction="column" justify="center" mt={10}>
              <Text size="sm" fw="500">
                Club Logo <span style={{ color: "#fb7852" }}>*</span>
              </Text>
              <Flex justify="center">
                {fileUrl ? (
                  <Image
                    fit="contain"
                    w={180}
                    h={120}
                    src={fileUrl || placeholder}
                    style={{ border: "1px solid #cdcaca" }}
                  />
                ) : (
                  <Image
                    fit="contain"
                    w={180}
                    h={120}
                    src={form.values.clubLogo || placeholder}
                    style={{ border: "1px solid #cdcaca" }}
                  />
                )}
              </Flex>
            </Flex>
            {isEditing && (
              <ImageUploader
                setFile={setFile}
                fileUrl={fileUrl}
                file={file}
                setFileUrl={setFileUrl}
              />
            )}
            <TextInput
              label="Club Manager Name"
              placeholder="Club Manager Name"
              readOnly
              value={clubManager}
            />
            <TextInput
              label="Club Contact First Name"
              placeholder="Club Contact First Name"
              withAsterisk
              readOnly={!isEditing}
              {...form.getInputProps("clubFirstName")}
            />
            <TextInput
              label="Club Contact Last Name"
              placeholder="Club Last Name"
              withAsterisk
              readOnly={!isEditing}
              {...form.getInputProps("clubLastName")}
            />
            <Flex direction="column">
              <Text size="sm" mb={4} fw={500}>
                Phone
                <span style={{ color: "red" }}> *</span>
              </Text>

              <PhoneInput
                defaultCountry="de"
                inputStyle={{
                  borderTopRightRadius: "24px",
                  borderBottomRightRadius: "24px",
                  width: "100%",
                }}
                {...form.getInputProps("clubPhone")}
                disabled={!isEditing}
              />
              {form.errors.clubPhone && (
                <Text c="red" size="xs" mt={2}>
                  {form.errors.clubPhone}
                </Text>
              )}
            </Flex>
            <TextInput
              label="Club Email"
              withAsterisk
              placeholder="Club Email"
              readOnly={!isEditing}
              {...form.getInputProps("clubEmail")}
            />
            <TextInput
              label="Club Street Address"
              placeholder="Club Street Address"
              withAsterisk
              readOnly={!isEditing}
              {...form.getInputProps("clubStreetAddress")}
            />
            <TextInput
              label="Club PostCode"
              withAsterisk
              placeholder="Club PostCode"
              readOnly={!isEditing}
              {...form.getInputProps("clubZipCode")}
            />
            <TextInput
              label="Club City"
              withAsterisk
              placeholder="Club City"
              readOnly={!isEditing}
              {...form.getInputProps("clubCity")}
            />
            <Select
              label="Club Country"
              withAsterisk
              placeholder="Club Country"
              searchable
              clearable
              disabled
              data={CountryList}
              {...form.getInputProps("clubCountry")}
            />
            <TextInput
              label="VAT Type"
              withAsterisk
              disabled
              placeholder="VAT Type"
              {...form.getInputProps("vatType")}
            />
            <TextInput
              label="Club VAT Number"
              withAsterisk
              placeholder="Club VAT Number"
              disabled
              {...form.getInputProps("clubVat")}
            />
            <TextInput
              label="Default Currency"
              withAsterisk
              placeholder="Default Currency"
              disabled
              {...form.getInputProps("defaultCurrency")}
            />
            <Select
              label="Clubs MOXSEA commission %"
              data={Array.from({ length: 101 }, (_, i) => `${i}`)}
              withAsterisk
              placeholder="Clubs MOXSEA commission %"
              readOnly={!isEditing}
              {...form.getInputProps("commission")}
            />
          </Flex>
          {isEditing ? (
            <Flex mt="md" justify="space-between">
              <Button
                onClick={() => {
                  handleCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Flex>
          ) : (
            <Flex mt="md">
              <Button
                size="sm"
                onClick={() => {
                  handleEditClick();
                }}
              >
                Edit
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default ViewClubs;
