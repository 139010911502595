import { createContext, useContext, useState, useEffect } from "react";
import { API_URLS } from "shared/Constants/api.const";
import http from "shared/utils/http";

import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";
import { USER_ROLE } from "shared/Constants/general.const";

const UserManagementContext = createContext({
  userData: [],
  fetchUsersList: () => {},
  addUserDetails: () => {},
  Clubs: {},
  clubDetails: [],
  regionsDetails: [],
  userDetailsData: [],
  fetchUserDetails: () => {},
  editUserDetails: () => {},
  harborsDetails: [],
  fetchHaborByRegion: () => {},
  loading: true,
  fetchRegionsByClub: () => {},
  getAllUserData: () => {},
  clubsList: [],
  regionsList: [],
  harborsList: [],
  updateRegionsList: () => {},
  updateHarborsList: () => {},
});

export const UserManagementnContextProvider = ({ children }) => {
  const { userDetails } = useUserContext();
  const userId = userDetails?.userId;
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetailsData, setUserDetailsData] = useState([]);
  const [clubsDetails, setClubsDetails] = useState([]);
  const [regionsDetails, setRegionsDetails] = useState([]);
  const [harborsDetails, setHarborsDetails] = useState([]);

  const [clubsList, setClubsList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [harborsList, setHarborsList] = useState([]);

  const fetchUsersList = async () => {
    const response = await http
      .get(`${API_URLS.USER_LIST}`)
      .then((data) => data)
      .catch((error) => {});
    setUserData(response?.users || []);
    setLoading(false);
  };
  const fetchUserDetails = async (userid) => {
    const response = await http
      .get(`${API_URLS.GET_USER_LIST_DETAILS.replace(":user-id", userid)}`)
      .then((data) => data)
      .catch((error) => {});

    setUserDetailsData(response || []);

    return response || [];
  };

  const addUserDetails = async (data) => {
    await http.post(API_URLS.ADD_USER, data);
    fetchUsersList(userId);
    fetchUsersList();
  };

  const editUserDetails = async (newUserDetails, userId) => {
    await http.put(
      `${API_URLS.GET_USER_LIST_DETAILS.replace(":user-id", userId)}`,
      newUserDetails
    );

    fetchUsersList();
  };

  const Clubs = async () => {
    const response = await http
      .get(`${API_URLS.USER_CLUBS_DETAILS}?userId=${userId}`)
      .then((data) => data)
      .catch((error) => {});

    const clubNamesAll = response?.clubs?.map((club) => {
      return {
        clubName: club.clubName,
        clubId: club.id,
      };
    });
    setClubsList(clubNamesAll);
    setClubsDetails(response?.clubs || []);
  };

  const fetchRegionsByClub = async (clubId) => {
    const response = await http
      .get(`${API_URLS.GET_REGIONS_BY_CLUB}${clubId}`)
      .then((data) => data)
      .catch((error) => {});
    setRegionsDetails(response?.regions || []);
    return response?.regions || [];
  };

  const fetchHaborByRegion = async (selectedRegionsIds) => {
    if (selectedRegionsIds) {
      const regionsIdsParamsString = selectedRegionsIds
        .map((regionIds) => `regionIds=${regionIds}`)
        .join("&");
      const response = await http
        .get(`${API_URLS.GET_HARBOURS_BY_REGION_IDS}?${regionsIdsParamsString}`)
        .then((data) => data)
        .catch((error) => {});
      setHarborsDetails(response?.harbors || []);

      return response?.harbors || [];
    }
  };

  const updateRegionsList = async (clubsData) => {
    let regionDetailsList = await fetchRegionsByClub(clubsData);

    let regionsNamesAll = [];
    if (regionDetailsList?.length > 0) {
      regionsNamesAll = regionDetailsList?.map((region) => {
        return {
          regionName: region.regionName,
          regionId: region.regionId,
        };
      });
    }

    setRegionsList(regionsNamesAll);
  };

  const updateHarborsList = async (regionsData) => {
    const harborDetailsList = await fetchHaborByRegion(regionsData);

    let harborsNamesAll = [];
    if (harborDetailsList?.length > 0) {
      harborsNamesAll = harborDetailsList?.map((harbor) => {
        return {
          harborName: harbor?.harborName || "",
          harborId: harbor?.id || "",
        };
      });
    }

    setHarborsList(harborsNamesAll);
  };

  const getAllUserData = async (userId) => {
    const userDetails = await fetchUserDetails(userId);

    const { role, accessedClubs, accessedRegions, accessedHarbors } =
      userDetails;

    if (role === USER_ROLE.Clubworker.value) {
      updateRegionsList(accessedClubs);
      updateHarborsList(accessedRegions);
    }
  };

  useEffect(() => {
    fetchUsersList(userId);
    Clubs();
  }, [userId]);

  return (
    <UserManagementContext.Provider
      value={{
        fetchRegionsByClub,
        userData,
        fetchUsersList,
        addUserDetails,
        Clubs,
        clubsDetails,
        regionsDetails,
        userDetailsData,
        fetchUserDetails,
        harborsDetails,
        editUserDetails,
        fetchHaborByRegion,
        getAllUserData,
        loading,
        clubsList,
        regionsList,
        harborsList,
        updateRegionsList,
        updateHarborsList,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};

export const useUserManagementContext = () => useContext(UserManagementContext);
